<template>
  <div class="jltyxpj">
    <div class="box1">
      <div class="titvalue">系统时钟设定</div>
      <div class="sjsd">
        <div class="hang1">
          <div class="biaoqian1">名称</div>
          <div class="biaoqian2">状态与设定</div>
        </div>
        <div class="hang1">
          <div class="biaoqian1">当前控制器时钟</div>
          <div class="biaoqian2"></div>
        </div>
        <div class="hang1">
          <div class="biaoqian1">交班时间1设定</div>
          <div class="biaoqian2"></div>
        </div>
        <div class="hang1">
          <div class="biaoqian1">交班时间2设定</div>
          <div class="biaoqian2"></div>
        </div>
        <div class="hang1">
          <div class="biaoqian1">交班时间3设定</div>
          <div class="biaoqian2"></div>
        </div>
        <div class="hang1">
          <div class="biaoqian1">日复位时间设定</div>
          <div class="biaoqian2"></div>
        </div>
        <div class="hang1">
          <div class="biaoqian1">月复位时间设定</div>
          <div class="biaoqian2">每月{{ infoList.DEVICE5V.ANSYS11 }}日</div>
        </div>
      </div>
      <div class="fwvalue">
        <div class="fwhang">
          <div class="fwtitle"></div>
          <div class="fwval">最小值</div>
          <div class="fwval">最大值</div>
          <div class="fwval">平均值</div>
        </div>
        <div class="fwhang" v-for="(item, index) in fwdata" :key="index">
          <div class="fwtitle">{{ item.lable }}</div>
          <div
            class="fwval shou"
            @click="
              toCompon(2, 'AN01', `DEVICE${index + 1}V`, 'AN01_FYF', '最小值')
            "
            @dblclick="
              Cclick(
                infoList[`DEVICE${index + 1}V`]['AN01'],
                'AN01',
                `DEVICE${index + 1}V`,
                'AN01_FYF'
              )
            "
          >
            {{ infoList[`DEVICE${index + 1}V`]["AN01"] }}
          </div>
          <div
            class="fwval shou"
            @click="
              toCompon(2, 'AN02', `DEVICE${index + 1}V`, 'AN02_FYF', '最小值')
            "
            @dblclick="
              Cclick(
                infoList[`DEVICE${index + 1}V`]['AN02'],
                'AN02',
                `DEVICE${index + 1}V`,
                'AN02_FYF'
              )
            "
          >
            {{ infoList[`DEVICE${index + 1}V`]["AN02"] }}
          </div>
          <div
            class="fwval shou"
            @click="
              toCompon(2, 'AN03', `DEVICE${index + 1}V`, 'AN03_FYF', '最小值')
            "
            @dblclick="
              Cclick(
                infoList[`DEVICE${index + 1}V`]['AN03'],
                'AN03',
                `DEVICE${index + 1}V`,
                'AN03_FYF'
              )
            "
          >
            {{ infoList[`DEVICE${index + 1}V`]["AN03"] }}
          </div>
        </div>
      </div>
    </div>

    <div class="box2">
      <div class="titvalue">运行评价参数</div>
      <div class="yxpjcs">
        <div class="hang">
          <div class="lie1">参数名称</div>
          <div class="lie2">参数评价</div>
          <div class="lie1">精度设定</div>
        </div>
        <div class="hang" v-for="(item, index) in yxpjdata" :key="index">
          <div class="lie1">{{ item.lable }}</div>
          <div class="lie2">
            <div
              class="anniu"
              :style="{
                backgroundColor: infoList[`DEVICE${item.id}V`][
                  `DGEV${item.zdid}`
                ]
                  ? 'green'
                  : 'red',
              }"
            >
              {{
                infoList[`DEVICE${item.id}V`][`DGEV${item.zdid}`] == true
                  ? "参与"
                  : "不参与"
              }}
            </div>
          </div>
          <div class="lie1">
            {{ infoList[`DEVICE${item.id}V`][`ANEV${item.zdid}`] }}
          </div>
        </div>
      </div>
      <div class="sjsd1" style="margin-top: 2vh">
        <div class="hang1">
          <div class="biaoqian1">设定时长</div>
          <div class="biaoqian2">{{ infoList.DEVICE1V.ANEV501 }}</div>
        </div>
        <div class="hang1">
          <div class="biaoqian1">已考核时长</div>
          <div class="biaoqian2">{{ infoList.DEVICE1V.ANEV502 }}</div>
        </div>
        <div class="hang1">
          <div class="biaoqian1">开始时间</div>
          <div class="biaoqian2"></div>
        </div>
        <div class="hang1">
          <div class="biaoqian1">结束时间</div>
          <div class="biaoqian2"></div>
        </div>
        <div class="hang1">
          <div class="biaoqian1">自定义考核状态</div>
          <div
            class="biaoqian2"
            style="display: flex; align-items: center; justify-content: center"
          >
            <div
              class="anniu"
              :style="{
                backgroundColor: infoList['DEVICE1V']['DGEVZT']
                  ? 'green'
                  : 'red',
              }"
            >
              {{ infoList["DEVICE1V"]["DGEVZT"] == true ? "参与" : "不参与" }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box3">
      <div class="titvalue3">自控率统计</div>
      <div class="yxpjcs">
        <div class="hang">
          <div class="lie1">回路名称</div>
          <div class="lie2">参与统计</div>
          <div class="lie2">投运状态</div>
          <div class="lie2">本班自控率</div>
        </div>
        <div class="hang" v-for="(item, index) in zkltj" :key="index">
          <div class="lie1">{{ item.lable }}</div>
          <div class="lie2">
            <div
              class="anniu"
              :style="{
                backgroundColor: infoList[`DEVICE${item.id}V`][
                  `DGEV${item.cyid}`
                ]
                  ? 'green'
                  : 'red',
              }"
            >
              {{
                infoList[`DEVICE${item.id}V`][`${item.name1}${item.cyid}`] ==
                true
                  ? "参与"
                  : "不参与"
              }}
            </div>
          </div>
          <div class="lie2">
            <div
              class="anniu"
              :style="{
                backgroundColor: infoList[`DEVICE${item.id}S`][
                  `${item.name2}${item.cyid}`
                ]
                  ? 'green'
                  : 'red',
              }"
            >
              {{
                infoList[`DEVICE${item.id}S`][`${item.name2}${item.cyid}`] ==
                true
                  ? "自动"
                  : "手动"
              }}
            </div>
          </div>
          <div class="lie2">
            {{ infoList[`DEVICE${item.id}V`][`${item.name3}${item.cyid}`] }}
          </div>
        </div>
      </div>
    </div>
    <div class="box4">
      <div class="titvalue">回路操作次数统计</div>
      <div class="yxpjcs">
        <div class="hang">
          <div class="lie1">回路名称</div>
          <div class="lie2">手动操作次数</div>
          <div class="lie2">自控操作次数</div>
      
        </div>
        <div class="hang" v-for="(item, index) in hltj" :key="index">
          <div class="lie1">{{ item.lable }}</div>
          <div class="lie2 shou"  @click="
              toCompon(2, `${item.name1}`, `${item.node1}`, `${item.name1}_FYF1`, `${item.lable}`)
            "
            @dblclick="
              Cclick(
                infoList[`${item.node1}`][`${item.name1}`],
                `${item.name1}`,
                `${item.node1}`,
                `${item.name1}_FYF1`
              )
            "
          >
            {{ infoList[`${item.node1}`][`${item.name1}`] }}
          </div>
          <div class="lie2">
            <!-- {{ infoList[`${item.node2}`][`${item.name2}`] }} -->
            {{ infoList[`${item.node2}`][`${item.name2}`] }}
          </div>
        </div>
      </div>
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
  </div>
</template>
  <script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name: "Jhindex",
  components: {
    Manual,
    Firstcontrol,
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
      fwdata: [
        {
          lable: "1#釜温",
          zdid: "01",
        },
        {
          lable: "2#釜温",
          zdid: "02",
        },
        {
          lable: "3#釜温",
          zdid: "03",
        },
        {
          lable: "4#釜温",
          zdid: "04",
        },
        {
          lable: "5#釜温",
          zdid: "05",
        },
      ],
      yxpjdata: [
        {
          lable: "JHF1釜温精度",
          zdid: "03",
          id: 1,
        },
        {
          lable: "JHF2釜温精度",
          zdid: "03",
          id: 2,
        },
        {
          lable: "JHF3釜温精度",
          zdid: "03",
          id: 3,
        },
        {
          lable: "JHF4釜温精度",
          zdid: "03",
          id: 4,
        },
        {
          lable: "JHF5釜温精度",
          zdid: "03",
          id: 5,
        },
        {
          lable: "热水罐温度",
          zdid: "01",
          id: 7,
        },
        {
          lable: "脱氧水罐液位",
          zdid: "04",
          id: 7,
        },
        {
          lable: "冷水罐液位",
          zdid: "05",
          id: 7,
        },
      ],
      zkltj: [
        {
          lable: "JHF1_1",
          cyid: "01",
          id: 1,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF1_2",
          cyid: "02",
          id: 1,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF1_3",
          cyid: "03",
          id: 1,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF2_1",
          cyid: "01",
          id: 2,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF2_2",
          cyid: "02",
          id: 2,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF2_3",
          cyid: "03",
          id: 2,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF3_1",
          cyid: "01",
          id: 3,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF3_2",
          cyid: "02",
          id: 3,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF3_3",
          cyid: "03",
          id: 3,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF4_1",
          cyid: "01",
          id: 4,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF4_2",
          cyid: "02",
          id: 4,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF4_3",
          cyid: "03",
          id: 4,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF5_1",
          cyid: "01",
          id: 5,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF5_2",
          cyid: "02",
          id: 5,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "JHF5_3",
          cyid: "03",
          id: 5,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "SXT1",
          cyid: "01",
          id: 7,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "SXT2",
          cyid: "02",
          id: 7,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "SXT3",
          cyid: "03",
          id: 7,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "SXT4",
          cyid: "04",
          id: 7,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
        {
          lable: "SXT5",
          cyid: "05",
          id: 7,
          name1: "DGZKL",
          name2: "SW",
          name3: "ANZKL",
        },
      ],
      hltj: [
        {
          lable: "夹套水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE1S",
          node2:"DEVICE2S",
          name1: "AI01",
          name2: "AI11",
        },
        {
          lable: "釜顶冷水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE1S",
          node2:"DEVICE2S",
          name1: "AI03",
          name2: "AI12",
        },
        {
          lable: "旁路排氮阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE2S",
          node2:"DEVICE2S",
          name1: "AI27",
          name2: "AI28",
        },
        {
          lable: "夹套水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE2S",
          node2:"DEVICE3S",
          name1: "AI01",
          name2: "AI11",
        },
        {
          lable: "釜顶冷水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE2S",
          node2:"DEVICE3S",
          name1: "AI03",
          name2: "AI12",
        },
        {
          lable: "旁路排氮阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE3S",
          node2:"DEVICE3S",
          name1: "AI27",
          name2: "AI28",
        },
        {
          lable: "夹套水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE3S",
          node2:"DEVICE4S",
          name1: "AI01",
          name2: "AI11",
        },
        {
          lable: "釜顶冷水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE3S",
          node2:"DEVICE4S",
          name1: "AI03",
          name2: "AI12",
        },
        {
          lable: "旁路排氮阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE3S",
          node2:"DEVICE4S",
          name1: "AI03",
          name2: "AI12",
        },
        {
          lable: "夹套水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE4S",
          node2:"DEVICE5S",
          name1: "AI01",
          name2: "AI11",
        },
        {
          lable: "釜顶冷水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE4S",
          node2:"DEVICE5S",
          name1: "AI03",
          name2: "AI12",
        },
        {
          lable: "旁路排氮阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE5S",
          node2:"DEVICE5S",
          name1: "AI27",
          name2: "AI28",
        },
        {
          lable: "夹套水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE5S",
          node2:"DEVICE7S",
          name1: "AI01",
          name2: "AI25",
        },
        {
          lable: "釜顶冷水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE5S",
          node2:"DEVICE7S",
          name1: "AI03",
          name2: "AI26",
        },
        {
          lable: "旁路排氮阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE1M__p__XK09",
          node2:"DEVICE1M__p__XK09",
          name1: "AI27",
          name2: "AI28",
        },
        {
          lable: "循环加热阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE7S",
          node2:"DEVICE2S",
          name1: "AI01",
          name2: "AI06",
        },
        {
          lable: "补水加热阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE7M__p__XK04",
          node2:"DEVICE2S",
          name1: "AI02",
          name2: "AI07",
        },
        {
          lable: "热水补冷水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE7M__p__XK04",
          node2:"DEVICE7S",
          name1: "AI03",
          name2: "AI08",
        },
        {
          lable: "冷水罐补水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE7S",
          node2:"DEVICE7S",
          name1: "AI04",
          name2: "AI09",
        },
        {
          lable: "脱氧塔补水阀",
          cyid: "01",
          id: 1,
          node1:"DEVICE7S",
          node2:"DEVICE7S",
          name1: "AI05",
          name2: "AI10",
        },
      ]
    };
  },
  mixins: [index],
  created() {
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
  },
  filters: {
    // 阀门红绿灯
    typeFifter: (val) => {
      if (val < 5) {
        return require("@/assets/images/截止阀.png");
      } else if (val >= 5) {
        return require("@/assets/images/截止阀2.png");
      }
    },
    //釜内红绿灯
    tyFifter: (val) => {
      if (val <= 10) {
        return require("@/assets/images/关闭.png");
      } else if (val > 10) {
        return require("@/assets/images/开始.png");
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.jltyxpj {
  display: flex;
  box-sizing: border-box;
  padding: 2vh 1vw;
  .titvalue {
    width: 20vw;
    height: 3vh;
    line-height: 3vh;
    text-align: center;
    color: #fff;
    font-size: 1.2vw;
    margin-bottom: 1vh;
  }
  .titvalue3 {
    width: 32vw;
    height: 3vh;
    line-height: 3vh;
    text-align: center;
    color: #fff;
    font-size: 1.2vw;
    margin-bottom: 1vh;
  }
  .sjsd1 {
    width: 20vw;
    height: 20vh;
    border: 1px solid rgba(0, 228, 255, 0.2);
    border-bottom: none;
    
    .hang1 {
      display: flex;
    }
    .biaoqian1 {
      width: 8vw;
      height: 4vh;
      color: #8aeaff;
      text-align: center;
      line-height: 4vh;
      border-bottom: 1px solid rgba(0, 228, 255, 0.2);
      border-right: 1px solid rgba(0, 228, 255, 0.2);
    }
    .biaoqian2 {
      width: 12vw;
      height: 4vh;
      color: #fff;
      text-align: center;
      line-height: 4vh;
      border-bottom: 1px solid rgba(0, 228, 255, 0.2);
      cursor: pointer;
      // border-right: 1px solid rgba(0, 228, 255, 0.2);
    }
  }
  .box1 {
    width: 20vw;
    .sjsd {
      width: 20vw;
      height: 29vh;
      border: 1px solid rgba(0, 228, 255, 0.2);
      border-bottom: none;
      .hang1 {
        display: flex;
      }
      .biaoqian1 {
        width: 8vw;
        height: 4vh;
        color: #8aeaff;
        text-align: center;
        line-height: 4vh;
        border-bottom: 1px solid rgba(0, 228, 255, 0.2);
        border-right: 1px solid rgba(0, 228, 255, 0.2);
      }
      .biaoqian2 {
        width: 12vw;
        height: 4vh;
        color: #fff;
        text-align: center;
        line-height: 4vh;
        border-bottom: 1px solid rgba(0, 228, 255, 0.2);
        border-right: 1px solid rgba(0, 228, 255, 0.2);
        cursor: pointer;
      }
    }

    .fwvalue {
      margin-top: 4vh;
      width: 20vw;
      height: 25vh;
      border: 1px solid rgba(0, 228, 255, 0.2);
      border-bottom: none;
      border-right: none;
      cursor: pointer;
      .fwhang {
        display: flex;
        border-bottom: 1px solid rgba(0, 228, 255, 0.2);
        border-right: 1px solid rgba(0, 228, 255, 0.2);
      }
      .fwtitle {
        width: 8vw;
        height: 4vh;
        line-height: 4vh;
        color: #8aeaff;
        text-align: center;
        // border-bottom: 1px solid rgba(0, 228, 255, 0.2);
        border-right: 1px solid rgba(0, 228, 255, 0.2);
      }
      .fwval {
        width: 4vw;
        height: 4vh;
        line-height: 4vh;
        color: #8aeaff;
        border-right: 1px solid rgba(0, 228, 255, 0.2);
        text-align: center;
      }
    }
  }
  .anniu {
    width: 5vw;
    height: 3vh;
    line-height: 3vh;
  }
  .box2 {
    width: 20vw;
    color: #8aeaff;

    margin-left: 2vw;
    .yxpjcs {
      border: 1px solid rgba(0, 228, 255, 0.2);
      .hang {
        display: flex;
        border-bottom: 1px solid rgba(0, 228, 255, 0.2);
      }
      .lie1 {
        width: 8vw;
        height: 4vh;
        line-height: 4vh;
        text-align: center;
        border-right: 1px solid rgba(0, 228, 255, 0.2);
      }
      .lie2 {
        width: 6vw;
        height: 4vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-right: 1px solid rgba(0, 228, 255, 0.2);
        cursor: pointer;
      }
    }
  }
  .box3 {
    width: 32vw;
    color: #8aeaff;

    margin-left: 2vw;
    .yxpjcs {
      border: 1px solid rgba(0, 228, 255, 0.2);
      .hang {
        display: flex;
        border-bottom: 1px solid rgba(0, 228, 255, 0.2);
      }
      .lie1 {
        width: 8vw;
        height: 3.5vh;
        line-height:3.5vh;
        text-align: center;
        border-right: 1px solid rgba(0, 228, 255, 0.2);
      }
      .lie2 {
        width: 8vw;
        height: 3.5vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-right: 1px solid rgba(0, 228, 255, 0.2);
        cursor: pointer;
      }
    }
  }
  .box4 {
    width: 30vw;
    color: #8aeaff;

    margin-left: 2vw;
    .yxpjcs {
      border: 1px solid rgba(0, 228, 255, 0.2);
      .hang {
        display: flex;
        border-bottom: 1px solid rgba(0, 228, 255, 0.2);
      }
      .lie1 {
        width: 8vw;
        height: 3.5vh;
        line-height:3.5vh;
        text-align: center;
        border-right: 1px solid rgba(0, 228, 255, 0.2);
      }
      .lie2 {
        width: 6vw;
        height: 3.5vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-right: 1px solid rgba(0, 228, 255, 0.2);
        cursor: pointer;
      }
    }
  }
  .shou{
    // cursor: pointer;
  }
}
</style>
  
  
  